/*
//margin-top
@margin-top-smaller: 1rem;
@margin-top-small: 2rem;
@margin-top-medium: 4rem;
@margin-top-big: 6rem;
@margin-top-large: 8rem;
@margin-top-larger: 10rem;

//margin-bottom
@margin-bottom-smaller: 1rem;
@margin-bottom-small: 2rem;
@margin-bottom-medium: 4rem;
@margin-bottom-big: 6rem;
@margin-bottom-large: 8rem;
@margin-bottom-larger: 10rem;
*/
.navbar-fixed-props {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  font-family: "Arial";
}
.image-clickable-side {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 101;
}
.image-slider-button {
  position: fixed;
  top: 50%;
  background-color: transparent;
  font-family: 'Fredoka One';
  color: #fff;
  font-weight: 600;
  font-size: 3.8rem;
  cursor: pointer;
  border: solid white;
  border-width: 0 8px 8px 0;
  border-radius: 10%;
  display: inline-block;
  padding: 2.5rem;
}
@media only screen and (max-width: 28.125rem) {
  .image-slider-button {
    top: 48%;
    padding: 1.8rem;
    border-width: 0 5px 5px 0;
  }
}
.pos-abs {
  position: absolute;
  top: 0;
  left: 0;
}
.bg-image {
  width: 100%;
  height: auto;
  display: block;
  background-size: cover;
}
.button-without-outline:focus {
  outline: none;
}
.button-push-animation:active {
  transform: translateY(2px);
}
.button-hover-animation:hover {
  opacity: 0.6;
}
.events-page {
  background-color: #fff;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
}
.top-section {
  min-height: 20rem;
  width: 100%;
  background-color: black;
  display: flex;
}
.top-section__title {
  align-self: flex-end;
  margin: 4rem 0 4rem 20%;
  color: #ffffff;
  font-size: 2.8rem;
  font-weight: 600;
}
.events-container {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
  margin: 5rem auto 10rem auto;
}
.events-container .event-card {
  margin: 0 auto 4rem auto;
  background-color: white;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  width: 45%;
}
.events-container .event-card__image-container {
  width: 100%;
  padding-top: 56.26%;
  overflow: hidden;
  position: relative;
}
.events-container .event-card__event-image {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.events-container .event-card__event-title {
  padding: 2rem;
  font-size: 2rem;
  font-weight: 600;
}
.events-container .event-card__event-short-text {
  padding: 0 2rem;
  color: #9d9d9d;
  font-size: 1.8rem;
  line-height: 1.6;
  text-align: justify;
}
.events-container .event-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem 2rem 2rem;
}
.events-container .event-card__event-date {
  text-align: right;
  color: #9d9d9d;
  font-size: 1.8rem;
}
.events-container .event-card__detailes-button {
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.6rem;
  color: black;
  background-color: white;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.25s;
}
.events-container .event-card__detailes-button:focus {
  outline: none;
}
.events-container .event-card__detailes-button:active {
  transform: translateY(2px);
}
.events-container .event-card__detailes-button:hover {
  opacity: 0.6;
}
.events-container .event-card:last-child {
  margin: 0 2.3% 4rem 2.3%;
}
@media only screen and (max-width: 75rem) {
  .events-container {
    width: 80%;
  }
}
@media only screen and (max-width: 56.25rem) {
  .events-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4rem auto;
    width: 100%;
    padding: 0;
  }
  .events-container .event-card {
    width: 70%;
  }
  .top-section {
    height: 150px;
  }
}
@media only screen and (max-width: 37.5rem) {
  .events-container {
    margin: 0;
    padding: 4rem;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .events-container .event-card {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 28.125rem) {
  .events-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .events-container .event-card__event-title {
    padding-bottom: 1rem;
  }
  .events-container .event-card__footer {
    flex-wrap: wrap;
  }
  .events-container .event-card__event-date {
    width: 100%;
    text-align: left;
    padding-bottom: 1rem;
  }
  .events-container .event-card__detailes-button {
    margin-left: auto;
    margin-right: 0;
  }
}
