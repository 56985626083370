/*
//margin-top
@margin-top-smaller: 1rem;
@margin-top-small: 2rem;
@margin-top-medium: 4rem;
@margin-top-big: 6rem;
@margin-top-large: 8rem;
@margin-top-larger: 10rem;

//margin-bottom
@margin-bottom-smaller: 1rem;
@margin-bottom-small: 2rem;
@margin-bottom-medium: 4rem;
@margin-bottom-big: 6rem;
@margin-bottom-large: 8rem;
@margin-bottom-larger: 10rem;
*/
.image-montage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 20vw);
  grid-gap: 0px;
}
.image-card {
  position: relative;
}
.image-card__dog-image {
  height: auto;
  width: 100%;
  display: block;
}
.image-card__darker-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.image-card__darker-bg:hover {
  z-index: 0;
}
.image-card__image-title {
  position: absolute;
  right: 4%;
  bottom: 8%;
  font-size: 2.4rem;
  text-align: right;
  font-weight: 700;
  color: #ffffff;
  z-index: 10;
}
.image-card:hover .image-card__darker-bg {
  display: none;
}
.close-sliding-montage {
  position: fixed;
  top: 8rem;
  left: -5%;
  margin: 0;
  padding: 0;
  z-index: 1000;
  border: none;
  background-color: transparent;
  font-family: "Fredoka One";
  color: white;
  font-weight: 700;
  font-size: 3.8rem;
  cursor: pointer;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
}
.close-sliding-montage:focus {
  outline: none;
}
.sliding-image-montage {
  position: fixed;
  top: 8rem;
  left: -80%;
  margin: 0;
  padding: 0;
  width: 80%;
  z-index: 1000;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 15vw);
  grid-gap: 0px;
}
.show-dog-closing-btn {
  left: 82%;
}
.hide-dog-closing-btn {
  left: -5%;
}
.show-dog-options {
  left: 0%;
}
.hide-dog-options {
  left: -80%;
}
@media (max-width: 75rem) {
  .close-sliding-montage {
    font-size: 2.8rem;
  }
  .image-card__image-title {
    font-size: 2.4rem;
  }
}
@media (max-width: 56.25rem) {
  .image-montage {
    width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(9, 60vw);
  }
  .sliding-image-montage {
    top: 4.5rem;
    width: 60%;
    height: 95vh;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(9, 1fr);
    overflow-y: scroll;
  }
  .image-card__image-title {
    font-size: 2.8rem;
  }
  .close-sliding-montage {
    left: -10%;
    top: 4.5rem;
  }
  .show-dog-closing-btn {
    left: 62%;
  }
  .hide-dog-closing-btn {
    left: -10%;
  }
  .show-dog-options {
    left: 0%;
  }
  .hide-dog-options {
    left: -60%;
  }
}
@media (max-width: 37.5rem) {
  .image-montage {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(9, 60vw);
  }
}
@media (max-width: 28.125rem) {
  .image-card__image-title {
    font-size: 1.8rem;
  }
}
/*
&__item {
        &--1 {
            background-color: orangered;
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }
        &--2 {
            grid-column: 2 / span 2;
            grid-row: 1/ span 1;
        }
        &--3 {
            grid-column: 3 / span 1;
            grid-row: 1/ span 1;
        }
        &--4 {
            grid-column: 1 / span 1;
            grid-row: 2 / span 1;
        }
        &--5 {
            grid-column: 2 / span 1;
            grid-row: 2 / span 1;
        }
        &--6 {
            grid-column: 3 / span 1;
            grid-row: 2 / span 1;
        }
        &--7 {
            grid-column: 1 / span 1;
            grid-row: 3 / span 1;
        }
        &--8 {
            grid-column: 2 / span 1;
            grid-row: 3 / span 1;
        }
        &--9 {
            grid-column: 3 / span 1;
            grid-row: 3 / span 1;
        }
    }
*/
