/*
//margin-top
@margin-top-smaller: 1rem;
@margin-top-small: 2rem;
@margin-top-medium: 4rem;
@margin-top-big: 6rem;
@margin-top-large: 8rem;
@margin-top-larger: 10rem;

//margin-bottom
@margin-bottom-smaller: 1rem;
@margin-bottom-small: 2rem;
@margin-bottom-medium: 4rem;
@margin-bottom-big: 6rem;
@margin-bottom-large: 8rem;
@margin-bottom-larger: 10rem;
*/
.navbar-fixed-props {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  font-family: "Arial";
}
.image-clickable-side {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 101;
}
.image-slider-button {
  position: fixed;
  top: 50%;
  background-color: transparent;
  font-family: 'Fredoka One';
  color: #fff;
  font-weight: 600;
  font-size: 3.8rem;
  cursor: pointer;
  border: solid white;
  border-width: 0 8px 8px 0;
  border-radius: 10%;
  display: inline-block;
  padding: 2.5rem;
}
@media only screen and (max-width: 28.125rem) {
  .image-slider-button {
    top: 48%;
    padding: 1.8rem;
    border-width: 0 5px 5px 0;
  }
}
.pos-abs {
  position: absolute;
  top: 0;
  left: 0;
}
.bg-image {
  width: 100%;
  height: auto;
  display: block;
  background-size: cover;
}
.button-without-outline:focus {
  outline: none;
}
.button-push-animation:active {
  transform: translateY(2px);
}
.button-hover-animation:hover {
  opacity: 0.6;
}
.more-dogs-section {
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 9rem;
}
.title-of-more-dogs {
  font-size: 2.8rem;
  font-weight: 600;
  text-align: center;
}
.cards-container {
  margin: 6rem auto 0 auto;
  display: flex;
  width: 80%;
}
.more-dog-card {
  margin: 0 auto;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  height: 22rem;
  padding: 2rem;
  width: 100%;
  background-color: #ffffff;
  display: flex;
}
.more-dog-card:not(:last-child) {
  margin-right: 3rem;
}
.more-dog-card__image-container {
  width: 18rem;
  height: 18rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.more-dog-card__image {
  height: 100%;
}
.more-dog-card__data {
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: calc(100% - 18rem);
}
.more-dog-card__data--title {
  width: 100%;
  margin-bottom: 2rem;
  align-self: center;
  font-size: 2.8rem;
  font-weight: 500;
  text-align: left;
}
.more-dog-card__data--button {
  align-self: flex-end;
  padding: 1rem 1.5rem;
  border: 2px solid #000000;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.2s;
}
.more-dog-card__data--button:focus {
  outline: none;
}
.more-dog-card__data--button:active {
  transform: translateY(2px);
}
.more-dog-card__data--button:hover {
  opacity: 0.6;
}
@media only screen and (max-width: 75rem) {
  .cards-container {
    flex-direction: column;
    width: 60%;
  }
  .more-dog-card {
    margin: 2rem auto;
  }
  .more-dog-card__image {
    height: 21.5rem;
  }
  .more-dog-card__title {
    margin: 0 6rem 2rem 4rem;
  }
}
@media only screen and (max-width: 56.25rem) {
  .cards-container {
    flex-direction: column;
    width: 70%;
  }
  .more-dog-card__title {
    min-width: 2.5rem;
    margin: 0 4rem 2rem 3rem;
  }
}
@media only screen and (max-width: 37.5rem) {
  .cards-container {
    flex-direction: column;
    width: 100%;
    padding: 0 2rem;
  }
}
@media only screen and (max-width: 28.125rem) {
  .cards-container {
    flex-direction: column;
  }
  .more-dog-card {
    min-height: 35rem;
    flex-direction: column;
    margin-right: 0 !important ;
  }
  .more-dog-card__image-container {
    width: 100%;
    height: auto;
  }
  .more-dog-card__data {
    width: 100%;
    padding-left: 0;
  }
  .more-dog-card__data--title {
    text-align: center;
    margin-top: 1.5rem;
    width: 100%;
    font-size: 1.8rem;
  }
  .more-dog-card__button {
    align-self: center;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem;
  }
}
