.organisations-list-component .organisations-list-header {
  background-color: black;
  color: white;
  font-size: 2.8rem;
  font-weight: 600;
  padding: 15rem 0 5rem;
}
.organisations-list-component .organisations-list-header__container {
  max-width: 1100px;
  margin: 0 auto;
}
.organisations-list-component .organisations-list {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 2rem;
}
.organisations-list-component .organisations-list__loading {
  font-size: 2rem;
  text-align: center;
}
.organisations-list-component .organisations-list__error {
  font-size: 2rem;
  text-align: center;
  background-color: #f0d1d1;
  padding: 2rem;
  font-size: 1.5rem;
  margin-top: 2rem;
}
.organisations-list-component .org-item {
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.10980392);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.11);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.11);
  margin-bottom: 2rem;
  padding: 2rem;
  display: grid;
  grid-template-columns: 30% auto;
  grid-template-rows: auto auto auto;
}
.organisations-list-component .org-item__image {
  grid-area: 1/1/4/2;
  width: 100%;
  height: auto;
  padding-right: 2rem;
}
.organisations-list-component .org-item__name {
  font-size: 2.2rem;
  font-weight: 600;
  padding-bottom: 1rem;
  grid-area: 1/2/2/3;
  margin-bottom: 3rem;
}
.organisations-list-component .org-item__desc {
  font-size: 1.5rem;
  line-height: 2.4rem;
  grid-area: 2/2/3/3;
}
.organisations-list-component .org-item__button {
  border: 2px solid black;
  padding: 1rem 2rem;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 5rem;
  display: inline-block;
  margin-left: auto;
  grid-area: 3/2/4/3;
}
@media (max-width: 1100px) {
  .organisations-list-component .organisations-list-header__container {
    padding: 2rem;
  }
  .organisations-list-component .organisations-list {
    padding: 2rem;
  }
}
@media (max-width: 900px) {
  .organisations-list-component .organisations-list-header {
    padding: 7rem 0 3rem;
  }
}
@media (max-width: 900px) {
  .organisations-list-component .org-item {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
  }
  .organisations-list-component .org-item__image {
    grid-area: 1/1/2/2;
    padding-right: 0;
  }
  .organisations-list-component .org-item__name {
    font-size: 2rem;
    margin-top: 2rem;
    grid-area: 2/1/3/2;
    margin-bottom: 2rem;
  }
  .organisations-list-component .org-item__desc {
    grid-area: 3/1/4/2;
  }
  .organisations-list-component .org-item__button {
    grid-area: 4/1/5/2;
  }
}
